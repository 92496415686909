import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';
// Configuración de tu proyecto Firebase
const firebaseConfig = {
  apiKey: "AIzaSyA_msouPTOd6MLqCUS3lj6A9MfiLlMRwEU",
  authDomain: "enventa-3eb02.firebaseapp.com",
  projectId: "enventa-3eb02",
  storageBucket: "enventa-3eb02.appspot.com",
  messagingSenderId: "844964940699",
  appId: "1:844964940699:web:07b938da200de52b35ef3e",
  measurementId: "G-LREDMX4P0P"
};

// Inicializamos la app de Firebase
export const firebaseApp = initializeApp(firebaseConfig);

// Inicializamos Firestore
// export const db = getFirestore(firebaseApp);
export const database = getDatabase(firebaseApp);
// Inicializamos Storage
export const storage = getStorage(firebaseApp);
// Exportamos referencias de Firestore (colecciones, por ejemplo)
// export const propertiesCollection = collection(db, 'properties');
