<template>
 <router-view></router-view>
 <Toaster />
</template>

<script>
import { Toaster } from 'vue-sonner';
export default {
  name: 'App',
  components: {
    Toaster
  }
}
</script>

<style>

</style>
